<template>
   <div class="main-container" style="padding-right:0;padding-left:0" dir="ltr">
    <section class="bg-gray pt-4 pr-15 pl-15" style="background: #fff;">
      <div class="container-fluid" dir="rtl">
        <div class="row justify-content-center" >
          <div class="col-xl-12 col-md-7" v-for="publish in CourseInfo" :key="publish.id">
              <img style="display: none" class="viewtube-player-single" :src="publish.img" />
                <div class="d-flex content-creator justify-content-between" dir="rtl">
                  <div class="my-auto">
                    <a href="#">
                      <img v-lazy="publish.img" alt=""></a>
                      <span class="pl-10">  {{publish.name_ar}}</span>
                  </div>
                  <!-- <div class="my-auto">
                    <a class="viewtube-btn subscribe" v-if="!pauseTrack" @click="play()">
                      <svg style="width: 25px;margin-left: 10px;" class="svg-icon" viewBox="0 0 20 20">
							        <path fill="#fff" d="M2.25,12.584c-0.713,0-1.292,0.578-1.292,1.291s0.579,1.291,1.292,1.291c0.713,0,1.292-0.578,1.292-1.291S2.963,12.584,2.25,12.584z M2.25,14.307c-0.238,0-0.43-0.193-0.43-0.432s0.192-0.432,0.43-0.432c0.238,0,0.431,0.193,0.431,0.432S2.488,14.307,2.25,14.307z M5.694,6.555H18.61c0.237,0,0.431-0.191,0.431-0.43s-0.193-0.431-0.431-0.431H5.694c-0.238,0-0.43,0.192-0.43,0.431S5.457,6.555,5.694,6.555z M2.25,8.708c-0.713,0-1.292,0.578-1.292,1.291c0,0.715,0.579,1.292,1.292,1.292c0.713,0,1.292-0.577,1.292-1.292C3.542,9.287,2.963,8.708,2.25,8.708z M2.25,10.43c-0.238,0-0.43-0.192-0.43-0.431c0-0.237,0.192-0.43,0.43-0.43c0.238,0,0.431,0.192,0.431,0.43C2.681,10.238,2.488,10.43,2.25,10.43z M18.61,9.57H5.694c-0.238,0-0.43,0.192-0.43,0.43c0,0.238,0.192,0.431,0.43,0.431H18.61c0.237,0,0.431-0.192,0.431-0.431C19.041,9.762,18.848,9.57,18.61,9.57z M18.61,13.443H5.694c-0.238,0-0.43,0.193-0.43,0.432s0.192,0.432,0.43,0.432H18.61c0.237,0,0.431-0.193,0.431-0.432S18.848,13.443,18.61,13.443z M2.25,4.833c-0.713,0-1.292,0.578-1.292,1.292c0,0.713,0.579,1.291,1.292,1.291c0.713,0,1.292-0.578,1.292-1.291C3.542,5.412,2.963,4.833,2.25,4.833z M2.25,6.555c-0.238,0-0.43-0.191-0.43-0.43s0.192-0.431,0.43-0.431c0.238,0,0.431,0.192,0.431,0.431S2.488,6.555,2.25,6.555z"></path>
						          </svg> تشغيل</a>
                      <a class="viewtube-btn subscribe" v-else @click="pause()">
                      <svg style="width: 25px;margin-left: 10px;" class="svg-icon" viewBox="0 0 20 20">
							<path d="M16.899,3.05c-0.085-0.068-0.192-0.095-0.299-0.074L7.947,4.779c-0.17,0.034-0.291,0.182-0.291,0.353v7.364c-0.494-0.536-1.199-0.873-1.983-0.873c-1.491,0-2.704,1.213-2.704,2.704s1.213,2.704,2.704,2.704c1.491,0,2.705-1.213,2.705-2.704V7.952l7.933-1.659v4.399c-0.494-0.535-1.199-0.873-1.983-0.873c-1.491,0-2.704,1.213-2.704,2.704c0,1.492,1.213,2.705,2.704,2.705c1.49,0,2.704-1.213,2.704-2.705V3.33C17.031,3.221,16.982,3.119,16.899,3.05 M5.673,16.311c-1.094,0-1.983-0.889-1.983-1.983s0.889-1.983,1.983-1.983c1.095,0,1.983,0.889,1.983,1.983S6.768,16.311,5.673,16.311 M14.327,14.508c-1.095,0-1.983-0.889-1.983-1.984c0-1.094,0.889-1.982,1.983-1.982c1.094,0,1.983,0.889,1.983,1.982C16.311,13.619,15.421,14.508,14.327,14.508 M16.311,5.558L8.377,7.217V5.428l7.933-1.659V5.558z"></path>
						</svg> ايقاف</a>
                   </div> -->
                </div>
                <div class="entry-content">
                  <h1 class="video-entry-title">{{publish.name_ar}}</h1>
                  <p>{{publish.name_en}}</p>
                </div>
          </div>
        </div>
        <div class="row justify-content-center " dir="rtl"  data-slick='{"slidesToShow": , "slidesToScroll": }'>
          <div class="col-12">
            <h4 style="margin-top: 5px;"> كل الفيديوهات</h4>
          </div>
          <div  class="col-6 col-sm-4 col-lg-4 col-xl-3" v-for="publish in All" :key="publish.id">
            <div class="card">
              <div class="video-item-card">
                <a @click="GoToSeriesContent(publish)">
                  <div class="card__cover">
                    <img v-lazy="publish.img" class="custom-logo" alt="">
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11 1C16.5228 1 21 5.47716 21 11C21 16.5228 16.5228 21 11 21C5.47716 21 1 16.5228 1 11C1 5.47716 5.47716 1 11 1Z" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.0501 11.4669C13.3211 12.2529 11.3371 13.5829 10.3221 14.0099C10.1601 14.0779 9.74711 14.2219 9.65811 14.2239C9.46911 14.2299 9.28711 14.1239 9.19911 13.9539C9.16511 13.8879 9.06511 13.4569 9.03311 13.2649C8.93811 12.6809 8.88911 11.7739 8.89011 10.8619C8.88911 9.90489 8.94211 8.95489 9.04811 8.37689C9.07611 8.22089 9.15811 7.86189 9.18211 7.80389C9.22711 7.69589 9.30911 7.61089 9.40811 7.55789C9.48411 7.51689 9.57111 7.49489 9.65811 7.49789C9.74711 7.49989 10.1091 7.62689 10.2331 7.67589C11.2111 8.05589 13.2801 9.43389 14.0401 10.2439C14.1081 10.3169 14.2951 10.5129 14.3261 10.5529C14.3971 10.6429 14.4321 10.7519 14.4321 10.8619C14.4321 10.9639 14.4011 11.0679 14.3371 11.1549C14.3041 11.1999 14.1131 11.3999 14.0501 11.4669Z" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>          
                  </div>
                </a>
                <div class="video-content">
                  <div class="d-flex">
                    <div class="my-auto " style="width: 100%;">
                      <a href="#">
                        <h5>{{publish.title_ar}}</h5>
                      </a>
                      <ul class="list-inline" style="justify-content: center;">
                        <li class="list-inline-item">
                          <a class="author ">{{publish.name_ar}}</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>                
          </div>
      </div>
    </div>
  </section>
   </div>
</template>

<script>
import config from '@/Api/config';
import { HTTP } from '@/Api/http-common';
import { ref } from '@vue/reactivity';
import { useRouter, useRoute } from "vue-router";
import { useCookie } from 'vue-cookie-next'
export default {
  props: {
      cat_id: String
  },
  name: 'AppSpeaking',
  async setup(props) {
    const router = useRouter();
    const cookie = useCookie();
    const All = ref([]);
    const CourseInfo = ref([]);
    
    try {
      await HTTP.get(`GetCourse.php?ID=${props.cat_id}`,{
        headers: {
          'Authorization': config.authHeader,
        },
      }).then((res) => {
        CourseInfo.value = res.data.CourseInfo; 
      });
    } catch (err) {
      console.log(err);
    }

    try {
      await HTTP.get(`GetCategoryContent.php?LIMIT=250&OFFSET=0&cat_id=${props.cat_id}`,{
        headers: {
          'Authorization': config.authHeader,
        },
      }).then((res) => {
        All.value = res.data.Content; 
      });
    } catch (err) {
      console.log(err);
    }

    const GoToSeriesContent = (publish) => {
        router.push({ name: "Contents", params: { content_id: publish.id } });
    };
    return { All ,CourseInfo, GoToSeriesContent};
    
  },
}
</script>

<style>

</style>